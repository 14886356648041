.header {
	position: fixed;
	top:0;
	left: 0;
	
	height: 42px;
	border-radius: 0px 0px 25px 0px;
	/* border: 2px solid #B89B72; */
	padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 5px;
	background-color: #353545;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	text-align: left;
	font-size: 14.0pt;
	text-transform: uppercase;

	z-index: 9;
}