.portfolio {
	/* background-color: #282D3C; */

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 52px;
	margin-bottom: 135px;

	color: #A09998;
	font-size: calc(10px + 2vmin);
	z-index: 4;
}

.profileVideo {
	background-color: #151525;
	box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.15);
	border-radius: 10px 10px 10px 10px;
	border: 5px solid #EDF1FE;

	align-items: center;
	justify-content: center;
	margin: 5px;

	width: 95%;
	max-width: 1080px;
	object-fit: cover;
}

.profileImage {
	background-color: #151525;
	box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.15);
	border-radius: 10px 10px 10px 10px;
	border: 5px solid #EDF1FE;

	align-items: center;
	justify-content: center;
	margin: 5px;

	width: 95%;
	max-width: 1080px;
	object-fit: cover;
}

.hr {
	width: 95%;
	margin: 25px;
	border: 1px solid #B89B72;
}

.animated-shapes {
	top: 0%;
	left: 0%;
	position: absolute;
	display: block;
	width: 100%;
	height: 95%;
	overflow: inherit;
}