.animation-area {
	background-image: radial-gradient(circle, #282D37, #282D3C);
	z-index: -2;
}

.animated-shapes {
	top: 0%;
	left: 0%;
	position: absolute;
	display: block;
	width: 100%;
	height: 95%;
}

.animated-shapes li {
	position: absolute;
	display: block;
	list-style: none;
	width: 25px;
	height: 25px;
	background: rgba(255, 255, 255, 0.25);
	mask: url(back_shape.svg);
	filter: blur(2px) brightness(50%);
	animation: animate 30s linear infinite;
	z-index: -1;
	overflow-x: hidden;
}

@keyframes animate {
	0% {
		transform: translateY(-200px) rotate(0deg);
	}
	5% {
		opacity: 0;
	}
	15% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	90% {
		opacity: 0;
	}
	100% {
		transform: translateY(1400px) rotate(360deg);
	}
}