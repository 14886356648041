a {
	color: rgb(180, 180, 180, 255);
}

a:hover {
	color: rgba(60, 180, 255, 1);
	text-decoration: none;
}

a:active {
	color: rgb(0, 0, 0, 255);
}

body {
	margin: 0;
	font-family: 'TTOctosquares-Regular', 'rigid-square', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* background-color: #282D3C; */
	background-image: radial-gradient(circle, #282D37, #282D3C);
	color: #EDF1FE;
}

h1 {
	font-family: 'TTOctosquares-Bold', 'rigid-square', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.setCursor {
	cursor: pointer;
	color: rgb(180, 180, 180, 255);
}

.setCursor:hover {
	color: rgba(60, 180, 255, 1);
}