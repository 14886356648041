.home {
	/* background-color: #282D3C; */

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 25%;
	margin-bottom: 125px;

	font-size: 12pt;
}

.profilePic {
	background-color: #151525;
	box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.15);
	border-radius: 30px 30px 30px 30px;
	border: 5px solid #EDF1FE;

	align-items: center;
	justify-content: center;
	margin: 5px;

	width: 15%;
	max-width: 1080px;
	object-fit: cover;
}

.text-blue {
	color: rgba(60, 180, 255, 1);
}