.footerM {
	position: fixed;
	left: 0;
	bottom: 0;
	
    padding-bottom: 5px;
	padding-top: 25px;
	width: 100%;
	height: 100px;
	background-color: #282D3C;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	text-transform: uppercase;
	z-index: 9;
}

.footerD {
	position: fixed;
	left: 0;
	bottom: 0;
	
    padding-bottom: 5px;
	padding-top: 5px;
	width: 100%;
	height: 40px;
	background-color: #282D3C;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	text-transform: uppercase;
	z-index: 9;
}