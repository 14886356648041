.menubarD {
    position: fixed;
	top: 0;
	right: 0;

	max-width: 285px;
	border-radius: 0px 0px 0px 25px;
	/* border: 2px solid #B89B72; */
	background-color: #353545;
    padding-top: 5px;
    padding-right: 15px;
	padding-left: 15px;
	
	text-align: right;
	font-size: 16.0pt;
	text-transform: uppercase;

    z-index: 10;
}

.menubarM {
    position: fixed;
	right: 0;
	bottom: 80px;
	
	width: 100%;
	border-radius: 20px 20px 20px 20px;
	/* border: 2px solid #B89B72; */
    background-color: #353545;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
	padding-bottom: 5px;
	
	font-size: 8.0pt;
	text-transform: uppercase;
	overflow-x: auto;
	
    z-index: 10;
}

.text-overflow {
    padding-top: 5px;
    padding-bottom: 10px;
	padding-left: 26.0pt;
	
    text-indent: -26.0pt;
    vertical-align: top;
}