.bio {
	/* background-color: #282D3C; */

	display: flex;
	margin-top: 25%;
	margin-bottom: 125px;

	font-size: 12pt;
	text-align: left;
}

.bio_message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.bio_pic {
	background-color: #151525;
	box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.15);
	border-radius: 10px 10px 10px 10px;
	border: 5px solid #EDF1FE;

	align-items: center;
	justify-content: center;
	margin: 5px;

	width: 95%;
	max-width: 1080px;
	object-fit: cover;
}

li {
	list-style: none;
}

.bio_title {
	font-weight: bold;
	position: relative;
	text-transform: uppercase;
}